/**
 * @file    main.css - The global CSS file.
 *
 * @author  Bryan Hoang <bryan@distributive.network>
 * @author  David Courtis <david@distributive.network>
 * @author  Kevin Yu <yu.kevin2002@gmail.com>
 * @date    Nov. 2023
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Theme colors, HSL based. */
    --background: 0 0% 0%;
    --foreground: 0 0% 100%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 158 72% 37%;
    --primary-foreground: 0 0% 100%;
    --secondary: 0 0% 100%;
    --secondary-foreground: 0 0% 100%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 217 10% 64%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 0 0% 76%;
    --input: 0 0% 76%;
    --ring: 0 0% 76%;
    --radius: 0.5rem;
    --overlay-background: 0 0% 22%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  @font-face {
    font-family: 'Proxima Nova';
    src: url('../assets/fonts/proxima-nova.otf');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Proxima Nova';
    src: url('../assets/fonts/proxima-nova-bold.otf');
    font-weight: bold;
  }

  /* stylelint-disable-next-line selector-id-pattern -- Next.js specific id. */
  html,
  body,
  #__next {
    height: 100%;
  }

  html {
    font-family: 'Proxima Nova', sans-serif;
  }

  body {
    @apply bg-background text-foreground;
  }

  /* stylelint-disable-next-line selector-id-pattern -- Next.js specific id. */
  #__next {
    /* Create a root stacking context */
    isolation: isolate;
    display: grid;
    grid-template-rows: auto 1fr;

    & > header {
      grid-row: 1 / 2;
    }

    & > main {
      grid-row: 2 / 3;
    }
  }

  select,
  input {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.dcp-gradient {
  background: linear-gradient(95deg, #0e2539 0%, #195654 100%);
}

.shadow-1 {
  box-shadow:
    rgb(50 50 93 / 25%) 0 2px 5px -1px,
    rgb(0 0 0 / 30%) 0 1px 3px -1px;
}

.ui-dots {
  overflow: hidden;
}

.ui-dots-container-top {
  background: url('../assets/gifs/dots.gif');
  z-index: 1;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
  width: 301px;
  height: 301px;
  overflow: hidden;
  mix-blend-mode: lighten;
  background-repeat: no-repeat, repeat;
  background-size:
    100% 100%,
    auto;
  position: absolute;
  background-position: center, top;
}

.ui-dots-container-middle {
  background: url('../assets/gifs/dots.gif');
  z-index: 1;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
  width: 301px;
  height: 301px;
  overflow: hidden;
  mix-blend-mode: lighten;
  background-repeat: no-repeat, repeat;
  background-size:
    100% 100%,
    auto;
  position: absolute;
  background-position: center, top;
}

.ui-dots-container-bottom {
  background: url('../assets/gifs/dots.gif');
  z-index: 1;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
  width: 301px;
  height: 301px;
  overflow: hidden;
  mix-blend-mode: lighten;
  background-repeat: no-repeat, repeat;
  background-size:
    100% 100%,
    auto;
  position: absolute;
  background-position: center, top;
}

.ui-dots-container-mobile {
  background: url('../assets/gifs/dots.gif');
  z-index: 1;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
  width: 301px;
  height: 301px;
  overflow: hidden;
  mix-blend-mode: lighten;
  background-repeat: no-repeat, repeat;
  background-size:
    100% 100%,
    auto;
  position: absolute;
  background-position: center, top;
}

.circular-finished {
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 6px solid #00ac69;
  animation: fill-animation 2s forwards;
}

.p-normal {
  font-size: 12px;
}

.dcp-text-col {
  color: #87c39b;
}

@keyframes fill-animation {
  from {
    background-color: transparent;
  }

  to {
    background-color: #39c08c;
  }
}

.ellipses::after {
  display: inline-block;
  animation: dotty steps(1, end) 2s infinite;
  content: '';
  height: 100%;
  line-height: 0;
  align-items: center;
}

@keyframes dotty {
  0% {
    content: '';
  }

  25% {
    content: '.';
  }

  50% {
    content: '..';
  }

  75% {
    content: '...';
  }

  100% {
    content: '';
  }
}
